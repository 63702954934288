import { FC } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import s from './ButtonLink.module.css';

import { ButtonLinkProps } from './ButtonLink.props';

import ArrowIcon from '@/public/icons/arrow-circle-down.svg';

export const ButtonLink: FC<ButtonLinkProps> = ({
  isWhite = false,
  withIcon = false,
  isTransparent = false,
  className,
  children,
  isInnerLink = false,
  href,
  ...props
}) => {
  const relValue = isInnerLink ? undefined : 'noreferrer noopener nofollow';
  return (
    <Link
      className={cn(
        'dark:border-accentAdditional dark:bg-accentAdditional dark:text-darkFullDark',
        'dark:hover:border-accentMain dark:hover:bg-accentMain dark:focus:border-accentMain dark:focus:bg-accentMain',
        'dark:active:border-accentAdditional dark:active:bg-accentMain dark:active:text-darkFullDark',
        s.btn,
        className,
        {
          [s.large]: withIcon,
          [s.white]: isWhite,
          [s.transparent]: isWhite && isTransparent,
        },
      )}
      href={href}
      rel={relValue}
      target={!isInnerLink ? '_blank' : undefined}
      {...props}
    >
      <span
        className={cn({
          'mr-2': withIcon,
        })}
      >
        {children}
      </span>
      {withIcon && <ArrowIcon className="aspect-square w-5" />}
    </Link>
  );
};
