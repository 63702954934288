import Image from 'next/image';
import dynamic from 'next/dynamic';

import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';

import { ALL_MENU_QUERY, MenuLocationEnum } from '@/data/allMenuQuery';
import {
  FOOTER_DATA_QUERY,
  FOOTER_DATA_QUERY_EN,
  FooterMenuLocationEnum,
} from '@/data/footerDataQuery';
import { HOME_PAGE_QUERY } from '@/data/homePageQuery';
import { flatListToHierarchical } from '@/utils/flatListToHierarchical';
import { getApolloClient } from '@/utils/apollo-client';
import { removeLastTrailingSlash } from '@/utils/removeLastTrailingSlash';
import { transformMenus } from '@/utils/transformMenus';
import { withLayout } from '@/layout/Layout';

import { PageProps } from '@/types/types';
import { LayoutProps } from '@/layout/Layout/Layout.props';

import { Heading } from '@/components/typography';
import { ButtonLink } from '@/components/buttons/ButtonLink';
import { Hero } from '@/components/common/Hero';
import { Section } from '@/components/common/Section';
import { footerDataTransformer } from '@/utils/footerDataTransformer';
import { useLocaleHook, useMediaQueryWidthPicker } from '@/hooks';
const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });
import { AnimatedCard } from '@/components/animation/AnimatedCard';
import { PageHead } from 'components/common/PageHead';
import Link from 'next/link';

const Home = ({ data }: PageProps & LayoutProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const locale = useLocaleHook();
  const isUk = locale === 'uk';
  const { device, screenWidth } = useMediaQueryWidthPicker();
  const isMobile = device === 'mobile';
  const isTablet = device === 'tablet';
  const heroTitle = t('heroTitle');

  const {
    featuredImage,
    frontPage: { about, productsSection },
    hero: { hero },
    seo,
  } = data.pageBy.translation;

  const featureImage = featuredImage?.node;

  useEffect(() => {
    const handleScroll = () => {
      const videoElement = videoContainerRef.current;
      const isNoImage =
        document.documentElement.classList.contains('hide-images');
      if (videoElement && !isNoImage) {
        const rect = videoElement.getBoundingClientRect();
        setIsVideoVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <PageHead
        title={seo?.title || heroTitle}
        canonicalUrl={seo?.canonicalUrl}
        seo={seo || {}}
        isMainPage
      />
      {isMounted && (
        <>
          <Hero isAccent isFeatureImage>
            <div className="grid gap-10 pb-5 text-white dark:text-darkFullDark md:pb-28 mdOnly:flex mdOnly:flex-col mdOnly:justify-between lg:pb-40">
              <div className="md:h-[200px] mdOnly:relative">
                <Heading
                  className="text-h2 font-bold md:text-[80px] md:leading-none"
                  tag="h1"
                  content={t('heroTitle')}
                  color="white"
                />
                <p
                  className="mt-6 block text-xxl font-bold md:w-[520px] md:text-[40px] md:leading-none mdOnly:absolute lg:mt-10 lg:w-full"
                  dangerouslySetInnerHTML={{ __html: hero?.title }}
                />
              </div>
              {hero?.descrPtion && (
                <p
                  className="text-base md:max-w-[80%] md:text-xl lg:max-w-[65%]"
                  dangerouslySetInnerHTML={{ __html: hero?.descrPtion }}
                />
              )}
            </div>
            {featureImage && (
              <div className="self-end">
                <Image
                  src={featureImage?.mediaItemUrl ?? ''}
                  alt={featureImage?.altText ?? ''}
                  width={featureImage?.mediaDetails?.width}
                  height={featureImage?.mediaDetails?.height}
                  className="ml-auto max-h-[300px] w-auto md:max-h-none"
                />
              </div>
            )}
          </Hero>
          <Section className="pt-[60px] md:pt-20 lg:pt-[100px]">
            <AnimatedCard>
              <Heading tag="p" withDivider content={about?.title} />
              {isMobile || isTablet ? null : (
                <div
                  className="relative aspect-video cursor-pointer md:w-[520px] lg:w-[800px]"
                  ref={videoContainerRef}
                >
                  {about?.video ? (
                    <ReactPlayer
                      url={about?.video}
                      width="100%"
                      height="100%"
                      playing={isVideoVisible}
                      playsinline={true}
                      controls={true}
                      muted={true}
                      poster={about?.videoPoster?.mediaItemUrl ?? ''}
                      origin={seo?.canonicalUrl}
                    />
                  ) : (
                    <Image
                      src={about?.videoPoster?.mediaItemUrl ?? ''}
                      alt={about?.videoPoster?.altText ?? ''}
                      width={800}
                      height={450}
                      className="cursor-pointer rounded"
                    />
                  )}
                </div>
              )}
            </AnimatedCard>
            <AnimatedCard>
              <div className="ml-auto grid max-w-[80%] gap-10 md:max-w-[510px] lg:mt-[100px] lg:max-w-[700px]">
                <p
                  className="normal text-base leading-5 md:text-lg"
                  dangerouslySetInnerHTML={{ __html: about?.description }}
                />
                {about?.buttonLink && (
                  <ButtonLink
                    isInnerLink
                    href={removeLastTrailingSlash(about?.pageUrl?.uri ?? '')}
                    className="justify-self-start"
                  >
                    {about?.buttonName}
                  </ButtonLink>
                )}
              </div>
            </AnimatedCard>
          </Section>
          <Section>
            <Heading tag="p" withDivider content={productsSection?.title} />
            <AnimatedCard>
              <div className="mb-[136px] ml-auto max-w-[80%] md:max-w-[510px] lg:max-w-[700px]">
                <p
                  className="text-[32px] font-bold leading-[36px] text-accentMain dark:text-accentAdditional"
                  dangerouslySetInnerHTML={{
                    __html: productsSection?.description,
                  }}
                />
              </div>
            </AnimatedCard>

            {productsSection?.categories?.length > 0 && (
              <ul className="mb-10 grid gap-8 md:grid-cols-2 lg:grid-cols-[repeat(auto-fit,minmax(0,_1fr))]">
                {productsSection?.categories.map((category, index) => {
                  return (
                    <li key={index}>
                      <AnimatedCard
                        initial={{
                          opacity: 0,
                          y: 100,
                          borderRadius: '50%',
                        }}
                        animate={{
                          opacity: 1,
                          y: 0,
                          borderRadius: '0%',
                        }}
                        delay={isMobile ? 0 : index * 0.15}
                        type="spring"
                        stiffness={200}
                        damping={12}
                      >
                        <Link
                          href={`/products?category=${removeLastTrailingSlash(
                            category?.slug,
                          )}`}
                          style={{
                            backgroundImage: `url('${category?.category?.ategoryImage?.mediaItemUrl}')`,
                            backgroundColor: `${category?.category?.ategoryColor}`,
                          }}
                          className="dark-hide-bg-image hide-bg-image flex aspect-square h-full items-center justify-center bg-contain bg-center bg-no-repeat p-2.5 py-4 text-center text-h2Mobile font-bold text-white  transition-shadow duration-300 hover:shadow-base focus:shadow-base"
                          aria-label={
                            isUk
                              ? `Перейти на сторінку ${category?.name}`
                              : `Go to ${category?.name} page`
                          }
                        >
                          <div
                            className="block"
                            style={{
                              width: isMobile
                                ? screenWidth - 60
                                : isTablet
                                ? screenWidth / 2 - 84
                                : screenWidth / 4 - 84,
                            }}
                          >
                            <p className="overflow-wrap break-words text-h3 text-white dark:text-darkFullDark dark:text-darkFullLight lg:text-h2Mobile">
                              {category?.name}
                            </p>
                          </div>
                        </Link>
                      </AnimatedCard>
                    </li>
                  );
                })}
              </ul>
            )}

            {about?.buttonLink && (
              <AnimatedCard>
                <ButtonLink
                  isInnerLink
                  href={removeLastTrailingSlash(
                    productsSection?.pageurl?.uri ?? '',
                  )}
                  className={'mx-auto max-w-[133px]'}
                >
                  {productsSection?.buttonname}
                </ButtonLink>
              </AnimatedCard>
            )}
          </Section>
        </>
      )}
    </>
  );
};

export default withLayout(Home);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const client = getApolloClient();

  const location =
    locale == 'uk'
      ? MenuLocationEnum.PRIMARY_NAVIGATION
      : MenuLocationEnum.PRIMARY_NAVIGATION___EN;
  const language = locale?.toUpperCase();

  const { data } = await client.query({
    query: HOME_PAGE_QUERY,
    variables: {
      language,
    },
  });
  const {
    data: { menuItems },
  } = await client.query({
    query: ALL_MENU_QUERY,
    variables: {
      location,
    },
  });

  const footerLocation =
    locale == 'uk'
      ? FooterMenuLocationEnum.FOOTER_MENU
      : FooterMenuLocationEnum.FOOTER_MENU___EN;

  const { data: footerMenuItems } =
    locale == 'uk'
      ? await client.query({
          query: FOOTER_DATA_QUERY,
          variables: {
            location: footerLocation,
          },
        })
      : await client.query({
          query: FOOTER_DATA_QUERY_EN,
          variables: {
            location: footerLocation,
          },
        });
  const footerData = footerDataTransformer(footerMenuItems);

  const flatList = flatListToHierarchical(menuItems.nodes);
  const menus = transformMenus(flatList);
  return {
    props: {
      data,
      menus,
      footerData,
      ...(await serverSideTranslations(locale as string, ['common'])),
    },
  };
};
