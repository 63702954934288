import cn from 'classnames';
import { FC } from 'react';
import { HeadingProps } from './Heading.props';

export const Heading: FC<HeadingProps> = ({
  tag = 'h2',
  content,
  color = 'black',
  fontWeight = 'bold',
  className,
  withDivider = false,
}) => {
  const conditionsForClass = {
    ['text-white dark:text-darkFullDark']: color === 'white',
    ['text-textPrimary dark:text-darkFullLight']: color === 'black',
    ['text-accentMain dark:gray-scale-red-diff']: color === 'accent-main',
  };

  switch (tag) {
    case 'h1':
      return (
        <h1
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          className={cn(conditionsForClass, fontWeight, className, {
            "relative pb-7 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-primaryLight before:content-[''] md:pb-10":
              withDivider,
          })}
        />
      );

    case 'h2':
      return (
        <h2
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          className={cn(conditionsForClass, fontWeight, className, {
            "relative mb-[60px] pb-7 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-primaryLight before:content-[''] dark:text-darkFullLight md:mb-[80px] md:pb-10 lg:mb-[100px]":
              withDivider,
          })}
        />
      );

    case 'h3':
      return (
        <h3
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          className={cn(conditionsForClass, fontWeight, className)}
        />
      );

    case 'p':
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          className={cn(conditionsForClass, fontWeight, className, {
            "custom-title relative mb-[60px] pb-7 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-primaryLight before:content-[''] dark:text-darkFullLight md:mb-[80px] md:pb-10 lg:mb-[100px]":
              withDivider,
          })}
        />
      );

    default:
      return null;
  }
};
