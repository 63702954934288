import { FC } from 'react';
import cn from 'classnames';
import { HeroProps } from './Hero.props';
import { Container } from '@/components/common/Container';
import { Breadcrumbs } from '@/components/navigation/Breadcrumbs';
import { useRouter } from 'next/router';

export const Hero: FC<HeroProps> = ({
  isAccent = false,
  isFeatureImage = false,
  className,
  children,
  breadcrumbs,
}) => {
  const router = useRouter();
  const isMainPage = router.asPath == '/';

  return (
    <section
      className={cn(className, {
        'pt-5 md:pt-9': !isMainPage,
        'pt-10 md:pt-25 lg:pt-[148px]': isMainPage,
        'bg-accentAdditional': isAccent,
        'bg-white dark:bg-darkFullDark': !isAccent,
      })}
    >
      <Container
        className={cn({
          // Якщо головна сторінка і є картинка, то з планшетної версії буде 2 колонки
          'md:grid md:grid-cols-[1fr_auto]': isMainPage && isFeatureImage,

          // Якщо не головна сторінка, то два рядки, де перший, це завжди хлібні крихти
          'md:grid-rows-[auto_1fr]': !isMainPage,
        })}
      >
        {/* Хлібні крихти, якщо не головна сторінка */}
        {!isMainPage && breadcrumbs && (
          <Breadcrumbs isDark={!isAccent} breadcrumbs={breadcrumbs} />
        )}

        {/* Якщо не головна сторінка та є картинка, тоді обгортаємо контент для Hero
        в обгортку, аби при його стилізації не брати до уваги хлібні крихти. В
        іншому випадку просто відмальовуємо дитячі компоненти */}

        {!isMainPage && isFeatureImage ? (
          <div className="grid grid-cols-[1fr_auto]">{children}</div>
        ) : (
          <>{children}</>
        )}
      </Container>
    </section>
  );
};
