import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { LazyMotion, m } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface AnimatedCardProps {
  className?: string;
  children: ReactNode;
  initial?: { [key: string]: number | string | string[] };
  animate?: { [key: string]: number | string | string[] };
  delay?: number;
  duration?: number;
  type?: 'spring' | 'tween';
  stiffness?: number;
  damping?: number;
  tag?: 'div' | 'li' | 'span' | 'p';
  ease?: string | number[];
}

export const AnimatedCard: FC<AnimatedCardProps> = ({
  children,
  className,
  initial,
  animate,
  delay,
  duration,
  type,
  stiffness,
  damping,
  tag = 'div',
  ease = 'linear',
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Анимация будет срабатывать только один раз
    rootMargin: '0px 0px -80px 0px', // Область видимости: 80px снизу
  });
  const defaultInitial = { opacity: 0, y: 40 };
  const newInitial: typeof initial = initial || defaultInitial;

  const newAnimate: typeof animate = animate
    ? Object.entries(animate).reduce((acc, [key, value]) => {
        acc[key as keyof typeof animate] = inView
          ? value
          : newInitial?.[key as keyof typeof newInitial];
        return acc;
      }, {} as typeof animate)
    : {
        opacity: inView ? 1 : 0,
        y: inView ? 0 : 40,
      };

  const MotionTag = m[tag];
  const lazyAnimation = () =>
    import('../../../utils/lazyAnimation').then(res => res.default);

  return (
    <LazyMotion features={lazyAnimation}>
      <MotionTag
        className={cn(className)}
        ref={ref}
        initial={newInitial}
        animate={newAnimate}
        transition={{
          duration: duration ?? 0.25,
          delay: delay ?? 0,
          type,
          stiffness,
          damping,
          ease,
        }}
      >
        {children}
      </MotionTag>
    </LazyMotion>
  );
};
