import Head from 'next/head';
import {
  BreadcrumbsProps,
  microMarkupSchemaProps,
  openGraphProps,
} from '@/types/types';
import { removeLastTrailingSlash } from '@/utils/removeLastTrailingSlash';
import { transformUrl } from '@/utils/transformUrl';
import { decodeText } from '@/utils/decodeText';

interface PageHeadProps {
  title?: string;
  canonicalUrl?: string;
  isMainPage?: boolean;
  microMarkupSchema?: microMarkupSchemaProps;
  breadcrumbs?: BreadcrumbsProps[];
  reviewSchema?: microMarkupSchemaProps;
  seo: {
    description?: string;
    openGraph?: openGraphProps;
  };
  descriptionChild?: string | undefined;
}

export const PageHead = ({
  title,
  canonicalUrl,
  seo,
  isMainPage,
  microMarkupSchema,
  breadcrumbs,
  reviewSchema,
  descriptionChild,
}: PageHeadProps): JSX.Element => {
  const canonicalUrlWithTrailingSlash = canonicalUrl
    ? removeLastTrailingSlash(canonicalUrl)
    : '';
  const twitterCard = seo?.openGraph?.twitterMeta?.card
    ? seo?.openGraph?.twitterMeta?.card.toLowerCase()
    : 'summary_large_image';

  const itemListElement =
    breadcrumbs &&
    breadcrumbs?.map((item: BreadcrumbsProps, index: number) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.text,
      item: transformUrl(item.url),
    }));

  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };

  const decodedTitle = decodeText(title);

  return (
    <Head>
      <title>{decodedTitle}</title>
      {(seo?.description || descriptionChild) && (
        <meta
          name="description"
          content={decodeText(seo.description) ?? decodeText(descriptionChild)}
        />
      )}
      {canonicalUrl && (
        <link rel="canonical" href={canonicalUrlWithTrailingSlash} />
      )}
      {/* Open Graph */}
      <meta
        property="og:title"
        content={decodeText(seo?.openGraph?.title) || decodedTitle}
      />
      {seo?.openGraph?.description && (
        <meta
          property="og:description"
          content={decodeText(seo.openGraph.description)}
        />
      )}
      {canonicalUrl && (
        <meta property="og:url" content={canonicalUrlWithTrailingSlash} />
      )}
      <meta
        property="og:site_name"
        content={decodeText(seo?.openGraph?.siteName) || decodedTitle}
      />
      <meta property="og:type" content="website" />

      {seo?.openGraph?.image && (
        <meta property="og:image" content={seo.openGraph.image.url} />
      )}
      {seo?.openGraph?.image?.secureUrl && (
        <meta
          property="og:image:secure_url"
          content={seo.openGraph.image.secureUrl}
        />
      )}
      {seo?.openGraph?.image?.width && (
        <meta property="og:image:width" content={seo.openGraph.image.width} />
      )}
      {seo?.openGraph?.image?.height && (
        <meta property="og:image:height" content={seo.openGraph.image.height} />
      )}
      {seo?.openGraph?.image?.type && (
        <meta property="og:image:type" content={seo.openGraph.image.type} />
      )}
      {/* Twitter */}
      <meta
        property="twitter:title"
        content={decodeText(seo?.openGraph?.twitterMeta?.title) || decodedTitle}
      />
      {seo?.openGraph?.twitterMeta?.description && (
        <meta
          property="twitter:description"
          content={decodeText(seo.openGraph.twitterMeta.description)}
        />
      )}
      {seo?.openGraph?.twitterMeta?.image && (
        <meta
          property="twitter:image"
          content={seo.openGraph.twitterMeta.image}
        />
      )}
      {seo?.openGraph?.twitterMeta?.card && (
        <meta property="twitter:card" content={twitterCard} />
      )}
      {isMainPage && (
        <meta
          name="google-site-verification"
          content="nw5Qs53aZZRIXgrHAl41C4dlmN3AvFfAwK4VQfu58YI"
        />
      )}
      {microMarkupSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(microMarkupSchema),
          }}
        />
      )}

      {reviewSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(reviewSchema),
          }}
        />
      )}

      {!isMainPage && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbSchema),
          }}
        />
      )}
    </Head>
  );
};
